import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router/index'
import store from '@/store'

import pdf from 'vue-pdf'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'

import i18n from '@/modules/i18n'
import Bugsnag from '@bugsnag/js'
import { msalInstance } from '@/modules/msal'
import vueModal from '@/modules/vueModal'
import browser from '@/modules/browser'
import dialog from '@/modules/dialog'
import scrollLock from '@/modules/scrollLock'
import vueLogger from '@/modules/vueLogger'

import bienvenue_components from '../node_modules/@bsoft/bienvenue_components/'

import { initWhiteLabel } from '@/modules/whiteLabel.js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

initWhiteLabel()

//Bienvenu Components
Object.values(bienvenue_components).forEach(component => {
  Vue.component(component.name, component)
})

Vue.component('pdf', pdf)
Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker)

//Global variables, based on .env file
//need to be rework (delete all variables and replace by process.env)
Vue.prototype.$isPartner = function() {
  return process.env.VUE_APP_PARTNER === 'true'
}

Vue.prototype.$allowSsoMicrosoft = function() {
  return process.env.VUE_APP_SSO_MICROSOFT_ALLOW === 'true'
}

Vue.prototype.$allowSSoGoogle = function() {
  return process.env.VUE_APP_GOOGLE_CLIENT_ID
}

Vue.prototype.$allowCrisp = function() {
  return process.env.VUE_APP_CRISP_ALLOW === 'true'
}

Vue.prototype.$appName = function() {
  return process.env.VUE_APP_NAME
}

Vue.prototype.$msalInstance = function() {
  return msalInstance
}

Vue.config.productionTip = false

//Crisp conf

if (process.env.VUE_APP_CRISP_ALLOW !== 'false') {
  window.$crisp = []
  window.CRISP_WEBSITE_ID = '33dbd12e-3614-42a1-92e8-43ee4f490a78'
  ;(function() {
    var d = document
    var s = d.createElement('script')

    s.src = 'https://client.crisp.chat/l.js'
    s.async = 1
    d.getElementsByTagName('head')[0].appendChild(s)
  })()

  // Désactiver l'avertissement concernant les shims
  $crisp.push(['safe', true])
}

Bugsnag.start({
  apiKey: `${process.env.VUE_APP_BUGSNAG_KEY}`,
  plugins: [new BugsnagPluginVue()]
})

const bugsnagVue = Bugsnag.getPlugin('vue')
bugsnagVue.installVueErrorHandler(Vue)

new Vue({
  store,
  i18n,
  Bugsnag,
  vueModal,
  browser,
  dialog,
  scrollLock,
  vueLogger,
  render: h => h(App),
  router
}).$mount('#app')
