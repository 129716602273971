import Vue from 'vue'
import VueRouter from 'vue-router'

import routes from '@/router/routes.js'
import store from '@/store'

import dayjs from 'dayjs'

import { getError, getItem, getRoleByLocalStorage } from '@/utils/function'

import i18n from '@/modules/i18n'
import { ACTUAL_ROLE } from '@/utils/consts'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

const loginPath = ['/microsoft/connect', '/LoginTeams']

router.beforeEach((to, from, next) => {
  //Force to redirect to login page if user is connected without role ('Trainer' or 'Sponsor')
  if (!getRoleByLocalStorage()) {
    localStorage.clear()
    sessionStorage.clear()
  }

  if (to.path === '/login') {
    store
      .dispatch('auth/getOrGenerate')
      .then(uuid => {
        if (uuid === 'teams_browser') {
          store.dispatch('auth/logout')
          next()
        } else {
          if (
            (!new URL(location.href).searchParams.get('type') &&
              new URL(location.href).searchParams.get('success') === '1') ||
            localStorage.getItem('url_redirection') !== null
          ) {
            store
              .dispatch('auth/validateSso')
              .then(() => {
                next()
              })
              .catch(error => {
                store.dispatch('utils/setError', {
                  title: i18n.t('utils.label.error'),
                  message: getError(error.response.data.detail)
                })
                next({ name: 'LoginPage' })
              })
          } else if (
            localStorage.getItem('token') ||
            (sessionStorage.getItem('token') && store.state.auth.user !== null)
          ) {
            next({ path: '/' })
          } else {
            next()
          }
        }
      })
      .catch(e => {
        next()
      })
  } else {
    if (loginPath.includes(to.path)) {
      next()
    } //need login path
    else {
      store
        .dispatch('auth/getOrGenerate')
        .then(uuid => {
          if (
            !(
              localStorage.getItem('token') ||
              sessionStorage.getItem('token') ||
              store.state.auth.token
            ) &&
            store.state.auth.user === null
          ) {
            next({ name: 'LoginPage' })
          } else {
            if (sessionStorage.getItem('uuidSession') === 'teams_browser') {
              next()
            } else {
              if (
                (localStorage.getItem('token') ||
                  sessionStorage.getItem('token') ||
                  store.state.auth.token) &&
                store.state.auth.user !== null
              ) {
                store.dispatch('auth/getUser').then(() => {
                  next()
                })
              } else {
                const date_login =
                  localStorage.getItem('date_login') ||
                  sessionStorage.getItem('date_login')
                const dateLogin = dayjs(date_login * 1000 + 3600 * 24 * 1000)

                sessionStorage.setItem('tokenSession', getItem('token'))
                if (getRoleByLocalStorage() === ACTUAL_ROLE.TRAINER) {
                  sessionStorage.setItem(
                    'trainerIdSession',
                    getItem('trainerId')
                  )
                } else if (getRoleByLocalStorage() === ACTUAL_ROLE.SPONSOR) {
                  sessionStorage.setItem(
                    'sponsorUuidSession',
                    getItem('sponsorUuid')
                  )
                }
                sessionStorage.setItem('uuidSession', getItem('uuid'))

                if (dateLogin.isAfter(dayjs())) {
                  if (getRoleByLocalStorage() === ACTUAL_ROLE.TRAINER) {
                    store
                      .dispatch('auth/getUser')
                      .then(() => {
                        if (to.path === '/login') {
                          next({ path: '/' })
                        }
                        next()
                      })
                      .catch(() => {
                        localStorage.clear()
                        sessionStorage.clear()
                        next({ name: 'LoginPage' })
                      })
                  } else if (getRoleByLocalStorage() === ACTUAL_ROLE.SPONSOR) {
                    if (localStorage.getItem('token') !== undefined) {
                      store
                        .dispatch('auth/getUser')
                        .then(() => {
                          if (to.path === '/login') {
                            next({ path: '/' })
                          }
                          next()
                        })
                        .catch(e => {
                          console.log(e)
                          next({ path: '/' })
                        })
                    } else {
                      if (to.path === '/login') {
                        next({ path: '/' })
                      }
                      next()
                    }
                    // pendant les tests
                    /*localStorage.clear()
                    sessionStorage.clear()
                    next({ name: 'LoginPage' })*/
                  }
                } else {
                  store
                    .dispatch('auth/tokenCheck')
                    .then(RESPONSE => {
                      if (RESPONSE !== 'VALID_TOKEN_EXISTS') {
                        localStorage.clear()
                        sessionStorage.clear()
                        next({ name: 'LoginPage' })
                      }

                      store
                        .dispatch('auth/getUser')
                        .then(() => {
                          next()
                        })
                        .catch(() => {
                          localStorage.clear()
                          sessionStorage.clear()

                          next({ name: 'LoginPage' })
                        })
                    })
                    .catch(() => {
                      localStorage.clear()
                      sessionStorage.clear()
                      next({ name: 'LoginPage' })
                    })
                }
              }
            }
          }
        })
        .catch(e => {
          next()
        })
    }
  }
})

router.afterEach(to => {
  Vue.nextTick(() => {
    document.title = `${i18n.t(
      `routes.title.${to.meta.title}`
    )} - Bienvenue Formation`
  })
})

export default router
