import api from '@/api/api'
import Vue from 'vue'

import {
  STATUS,
  STATE,
  ROLE,
  ERROR_403,
  LOGIN_STEEP,
  ERROR_400,
  CUSTOM_FIELD_ENTITY
} from '@/utils/consts'

import { timestampsSecondNow } from '@/utils/date'
import i18n from '@/modules/i18n'
import Bugsnag from '@bugsnag/js'
import { filterCustomFields } from '@/utils/function'

export default {
  createMicrosoftTabChanel({ commit, state }, { signatureUrl, sectionId }) {
    return api.auth
      .post('microsoft/channelTab', {
        signature_url: signatureUrl,
        section_id: sectionId
      })
      .then(res => {
        return Promise.resolve()
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },
  refreshTraineeAttendances({ commit, state }, dayNumber) {
    return api.auth
      .get(`sections/${state.section.id}/attendances`)
      .then(result => {
        if (result != null && result.trainee_attendances != null) {
          result.trainee_attendances.forEach(trainee_attendance => {
            if (trainee_attendance.day === dayNumber) {
              commit('UPDATE_ATTENDEE', {
                personUpdated: trainee_attendance,
                role: ROLE.TRAINEE
              })
            }
          })
        }
        if (result != null && result.trainer_attendances != null) {
          result.trainer_attendances.forEach(trainer_attendance => {
            if (trainer_attendance.day === dayNumber) {
              commit('UPDATE_ATTENDEE', {
                personUpdated: trainer_attendance,
                role: ROLE.TRAINER
              })
            }
          })
        }
        //todo else add new trainee
      })
  },

  setCurrentPart({ commit }, part) {
    commit('SET_CURRENT_PART', part)
  },
  getComments({ commit }, { sectionId }) {
    return api.auth
      .get(`sections/${sectionId}/comments`)
      .then(commentList => {
        return Promise.resolve(commentList)
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },
  async getSection({ commit, state }, { sectionId, dayNumber }) {
    try {
      const newSection = await api.auth.get(`sections/${sectionId}`)
      const customFields = await api.auth.get(`custom-fields`)

      commit('SET_SECTION', {
        section: newSection,
        day: dayNumber,
        customFields: customFields.items
      })
      commit('SET_DAY', dayNumber)
      commit('SET_CURENT_STATE_DAY', state.section.status)

      const parts = state.section.day.parts
      const partsLength = parts.length
      let part = null
      let curentPart = 0

      //recherche de la partie en cour (1 er non fermer)
      for (let i = 0; i < partsLength; i++) {
        part = parts[i]
        curentPart = i

        if (part.status !== STATE.CLOSED) {
          break
        }
      }
      commit('SET_CURRENT_PART', curentPart)

      calcStateSlot(commit, part)

      commit('SET_UPDATED_SECTION', newSection)
      return state.section
    } catch (e) {
      return e
    }
  },

  refreshSectionById({ commit, state }, { sectionId }) {
    return api.auth
      .get(`sections/${sectionId}`)
      .then(newSection => {
        commit('SET_CURENT_STATE_DAY', state.section.status)
        let parts = state.section.day.parts
        let part = parts[state.currentPart]
        calcStateSlot(commit, part)
        return Promise.resolve(newSection)
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },
  resetSection({ commit }) {
    commit('RESET_SECTION')
  },
  setDay({ commit }, day) {
    commit('SET_DAY', day)
  },
  resetDay({ commit }) {
    commit('RESET_DAY')
  },
  setEmergeOnApp({ commit }, flag) {
    commit('SET_EMERGE_ON_APP', flag)
  },
  sendByEmail({ commit, state }, { listAttendee }) {
    let listIdAttendees = []
    let comp = -1
    for (let i = 0, length = listAttendee.length; i < length; i++) {
      if (i % 5 === 0) {
        comp++
        listIdAttendees[comp] = []
      }
      listIdAttendees[comp][i % 5] = listAttendee[i].sequence_id
    }

    let listPromise = []
    listIdAttendees.forEach((id, index) => {
      listPromise.push(
        api.auth.post(`sections/${state.section.id}/remoteSignatures`, {
          attendances: listIdAttendees[index]
        })
      )
    })
    return Promise.all(listPromise).then(function(values) {
      let listResponse = values.reduce((responses, response) => {
        responses = responses.concat(response)
        return responses
      }, [])
      commit('SET_EMAIL_ATTENDEE', {
        emailAttendees: listResponse
      })
    })
  },
  addSignatureAndPresent({ commit }, { id, signature, role, part }) {
    return api.auth
      .patch(`${role}Attendances/${id}`, {
        updated_at: timestampsSecondNow(),
        status: STATUS.PRESENT.api
      })
      .then(() => {
        return api.auth
          .putSign(`${role}Attendances/${id}/signature`, signature)
          .then(personUpdated => {
            commit('UPDATE_ATTENDEE', {
              personUpdated: personUpdated,
              role: role
            })
            return Promise.resolve(personUpdated)
          })
          .catch(e => {
            return Promise.reject(e)
          })
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },
  addSignature({ commit, getters }, { id, signature, role, sequence }) {
    return api.auth
      .putSign(`${role}Attendances/${id}/signature`, signature)
      .then(personUpdated => {
        commit('UPDATE_ATTENDEE', {
          personUpdated: personUpdated,
          role: role
        })
        return Promise.resolve(personUpdated)
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },
  addAttendance({ commit, getters }, { id, signature, role, sequence }) {
    return api.auth
      .patch(`${role}Attendances/${id}`, {
        updated_at: timestampsSecondNow(),
        status: STATUS.PRESENT.api
      })
      .catch(e => {
        Vue.$log.fatal(e)
        return Promise.reject(e)
      })
  },
  closeSection({ commit, state, getters }, { dayNumber, currentSequence }) {
    return api.auth
      .post(`sections/${state.section.id}/close`, {
        day: dayNumber,
        halfDay: currentSequence
      })
      .then(res => {
        // commit("CLOSED_SECTION");
        return Promise.resolve(res)
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },

  setSurvey({ commit, state }, { survey_id, registrations_ids }) {
    let idsRegistrations = []
    let comp = -1
    for (let i = 0, length = registrations_ids.length; i < length; i++) {
      if (i % 5 === 0) {
        comp++
        idsRegistrations[comp] = []
      }
      idsRegistrations[comp][i % 5] = registrations_ids[i].registration_id
    }

    let listPromise = []
    idsRegistrations.forEach(registration_ids => {
      listPromise.push(
        api.auth.post(`sections/${state.section.id}/survey-entries`, {
          survey_id,
          registrations_ids: registration_ids
        })
      )
    })
    return Promise.all(listPromise).then(function(values) {
      let errors = []
      for (let i = 0, length = values.length; i < length; i++) {
        for (let [key, { success }] of Object.entries(values[i])) {
          if (!success) {
            errors = [...errors, key]
          }
        }
      }
    })
  },
  showAttendeeDetail({ commit }, { flag, id }) {
    commit('SHOW_PERSON_DETAIL', { flag, id })
  },
  sendComment({ commit, state }, { comment }) {
    return api.auth.post(`sections/${state.section.id}/comments`, {
      updated_at: parseInt(new Date().getTime() / 1000),
      content: comment,
      day: state.dayNumber
    })
  },
  sendCollectiveSign({ commit, state, getters }, { confidential }) {
    return api.auth
      .post(`sections/${state.section.id}/remoteCollectiveSignature`, {
        confidential,
        sequence: getters.currentSequence,
        day: state.dayNumber
      })
      .then(({ url }) => {
        commit('SET_COLLECTIVE_LINK', url)
        return Promise.resolve(url)
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },

  setCodeQR({ commit, state }, code) {
    commit('SET_CODE_QR', code)
  },

  sendDynamicQRCodeSign({ commit, state, dispatch }, trainingDayInfo) {
    return api.auth
      .put(
        `trainer/trainee-temporal-signing?${new URLSearchParams({
          section_id: trainingDayInfo.sectionId,
          day: trainingDayInfo.day,
          sequence: trainingDayInfo.sequence
        }).toString()}`,
        {}
      )
      .then(data => {
        return Promise.resolve(data)
      })
      .catch(error => {
        if (error.response && error.response.status === 403) {
          let detail = error.response.data.detail
          dispatch(
            'utils/setError',
            {
              title: i18n.t('utils.label.error'),
              message: `${ERROR_403[detail].message}`
            },
            { root: true }
          )
        } else if (
          error.response &&
          error.response.data.detail ===
            ERROR_400.TEMPORAL_SIGNING_NOT_ALLOWED.label
        ) {
          let detail = error.response.data.detail
          dispatch(
            'utils/setError',
            {
              title: i18n.t('utils.label.error'),
              message: `${ERROR_400[detail].message}`
            },
            { root: true }
          )
        } else if (
          error.response &&
          error.response.data.detail === ERROR_400.SECTION_NOT_ONGOING.label
        ) {
          let detail = error.response.data.detail
          dispatch(
            'utils/setError',
            {
              title: i18n.t('utils.label.error'),
              message: `${ERROR_400[detail].message}`
            },
            { root: true }
          )
        } else {
          dispatch('utils/setCommonError', error, { root: true })
        }
        return Promise.reject(error)
      })
  },

  setSectionSimple({ commit }, id) {
    return api.auth
      .get(
        `
    sections /${id}`
      )
      .then(data => {
        commit('SET_SECTION_SIMPLE', data)
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },
  setCurrentStateSlot({ commit }, status) {
    commit('SET_CURENT_STATE_SLOT', status)
  },
  patchTraineeAttendancesGaps(
    { commit },
    { traineeAttendanceId, arrival_lateness }
  ) {
    if (arrival_lateness < 0) {
      arrival_lateness = 0
    }
    return api.auth
      .patch(`traineeAttendances/${traineeAttendanceId}/gaps`, {
        arrival_lateness: arrival_lateness
      })
      .then(data => {
        commit('UPDATE_ATTENDEE', {
          personUpdated: data,
          role: ROLE.TRAINEE
        })
        return Promise.resolve(data)
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },
  patchTraineeAttendancesEarlyDeparture(
    { commit },
    { traineeAttendanceId, departure_earliness }
  ) {
    if (departure_earliness < 0) {
      departure_earliness = 0
    }
    return api.auth
      .patch(`traineeAttendances/${traineeAttendanceId}/gaps`, {
        departure_earliness: departure_earliness
      })
      .then(data => {
        commit('UPDATE_ATTENDEE', {
          personUpdated: data,
          role: ROLE.TRAINEE
        })
        return Promise.resolve(data)
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },
  updateSection({ commit, dispatch }, updateSection) {
    return api.auth
      .put(`sections/${updateSection.doNotEdit.id}`, {
        code: updateSection.editCode.code,
        name: updateSection.editName.name,
        type: updateSection.editType.type,
        mode: updateSection.editLocation.mode.type,
        location: updateSection.editLocation.location,
        address: updateSection.editLocation.address,
        zipcode: updateSection.editLocation.zipcode,
        city: updateSection.editLocation.city,
        country: updateSection.editLocation.country,
        start_at: updateSection.editPlanning.start_at, //require
        end_at: updateSection.editPlanning.end_at, //require
        auto_create_sequences: updateSection.editPlanning.auto_create_sequences,
        sign_before_slot_start_delay:
          updateSection.editOptions.sign_before_slot_start_delay,
        morning_start: updateSection.editPlanning.morning_start,
        morning_end: updateSection.editPlanning.morning_end,
        afternoon_start: updateSection.editPlanning.afternoon_start,
        afternoon_end: updateSection.editPlanning.afternoon_end,
        tags: updateSection.editTags.tags.length
          ? updateSection.editTags.tags
          : [''],
        site_id: updateSection.editOptions.site.id,
        timezone: updateSection.editPlanning.timezone
      })
      .then(res => {
        return Promise.resolve(res)
      })
      .catch(e => {
        if (e.response.data.status === 400) {
          dispatch('utils/setErrorData', e.response.data.violations, {
            root: true
          })
        } else {
          Bugsnag.leaveBreadcrumb('update section error', {
            errorData: e.response.data
          })
          dispatch('utils/setCommonError', e, { root: true })
        }
        return Promise.reject(e)
      })
  },

  searchTags({ commit, getters, rootGetters, dispatch }, keyword) {
    return api.auth
      .get(`${rootGetters['portal/getUserType']}/search-tags`, {
        keyword: keyword
      })
      .then(tagList => {
        return Promise.resolve(tagList)
      })
      .catch(e => {
        dispatch('utils/setCommonError', e, { root: true })
        return Promise.reject(e)
      })
  },

  searchSite({ commit, getters, rootGetters, dispatch }, keyword) {
    return api.auth
      .get(`${rootGetters['portal/getUserType']}/search-sites`, {
        keyword: keyword
      })
      .then(siteList => {
        return Promise.resolve(siteList)
      })
      .catch(e => {
        dispatch('utils/setCommonError', e, { root: true })
        return Promise.reject(e)
      })
  },

  setIsSectionEndDateEdited({ commit }, bool) {
    commit('SET_IS_SECTION_END_DATE_EDITED', bool)
  },

  setIsUpdateRegistrationModal({ commit }, bool) {
    commit('SET_IS_UPDATE_REGISTRATION_MODAL', bool)
  },

  getTraineeRegistration(
    { rootGetters, dispatch, commit },
    { id, firstname, lastname }
  ) {
    commit('SET_TRAINEE_INFO', { firstname: firstname, lastname: lastname })
    return api.auth
      .get(`${rootGetters['portal/getUserType']}/trainee-registrations/${id}`)
      .then(traineeRegistrations => {
        commit('SET_UPDATED_TRAINEE_REGISTRATION', traineeRegistrations)
        return Promise.resolve(traineeRegistrations)
      })
      .catch(e => {
        dispatch('utils/setCommonError', e, { root: true })
        return Promise.reject(e)
      })
  },

  getTraineeCustomFieldsRegistration(
    { rootGetters, dispatch, commit },
    { id, allCustomFields }
  ) {
    return api.auth
      .get(`trainee-registration-custom-field-values`, {
        trainee_registration_ids: [id]
      })
      .then(traineeCustomFieldsRegistration => {
        let filteredCustomFields = filterCustomFields(
          allCustomFields,
          CUSTOM_FIELD_ENTITY.TRAINEE_REGISTRATION
        )
        commit('SET_FILTERED_CUSTOM_FIELDS', {
          filteredCustomFields: filteredCustomFields,
          traineeRegistrationId: id
        })
        commit('SET_VALUE_CUSTOM_FIELDS', traineeCustomFieldsRegistration)
        return Promise.resolve(traineeCustomFieldsRegistration)
      })
      .catch(e => {
        dispatch('utils/setCommonError', e, { root: true })
        return Promise.reject(e)
      })
  },

  updateRegistration({ rootGetters, dispatch, commit }, updateRegistration) {
    return api.auth
      .put(
        `${rootGetters['portal/getUserType']}/trainee-registrations/${updateRegistration.id}`,
        {
          reference: updateRegistration.reference,
          financing_method: updateRegistration.financing_method,
          meal_included: updateRegistration.meal_included
        }
      )
      .then(res => {
        return Promise.resolve(res)
      })
      .catch(e => {
        if (e.response.data.status === 400) {
          dispatch('utils/setErrorData', e.response.data.violations, {
            root: true
          })
        } else {
          Bugsnag.leaveBreadcrumb('update registration error', {
            errorData: e.response.data
          })
          dispatch('utils/setCommonError', e, { root: true })
        }
        return Promise.reject(e)
      })
  },

  updateRegistrationCustomFields(
    { rootGetters, dispatch },
    { traineeRegistrationId, customFieldId, value }
  ) {
    return api.auth
      .put(
        `trainee-registration-custom-field-values?trainee_registration_id=${traineeRegistrationId}&custom_field_id=${customFieldId}`,
        {
          value: value
        }
      )
      .then(res => {
        return Promise.resolve(res)
      })
      .catch(e => {
        dispatch('utils/setErrorData', e.response.data, {
          root: true
        })
        dispatch('utils/setCommonError', e, { root: true })
        Bugsnag.leaveBreadcrumb('update registration custom field error', {
          errorData: e.response.data
        })
        return Promise.reject(e)
      })
  },

  setTraineeRegistration({ commit }, traineeRegistration) {
    commit('SET_UPDATED_TRAINEE_REGISTRATION', traineeRegistration)
  }
}

function calcStateSlot(commit, part) {
  commit('SET_CURENT_STATE_SLOT', part.status)
}
