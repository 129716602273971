import api from '@/api/api'

import dayjs from 'dayjs'

import {
  TYPE_LOGIN,
  ERROR_403,
  MODE_SENDING_CODE,
  CONNEXION_METHODE,
  LOGIN_STEEP,
  ACTUAL_ROLE,
  SSO_VALUE
} from '@/utils/consts'

import { getRoleByLocalStorage, isSingleElementInArray } from '@/utils/function'
import Bugsnag from '@bugsnag/js'

import i18n from '@/modules/i18n'
import { logoutSponsor } from '@/sponsorRole/sponsorFunction'
import { logoutTrainer } from '@/trainerRole/trainerFunction'
import apiSponsor from '@/api/apiSponsor'

/**
 *
 * @returns {{connexionSSO: boolean, simpleLogout: boolean}|{isConnexionGoogle: boolean, connexionSSO: boolean}|{connexionSSO: boolean}|{isConnexionMicrosoft: boolean, connexionSSO: boolean}}
 */
function isConnexionMethodSSO(organization) {
  if (organization.currentConnectionMethod === CONNEXION_METHODE.SSO_MICROSOFT)
    return {
      isConnexionMicrosoft: true,
      connexionSSO: true
    }
  else if (
    organization.currentConnectionMethod === CONNEXION_METHODE.SSO_GOOGLE
  )
    return { isConnexionGoogle: true, connexionSSO: true }
  else if (organization.currentConnectionMethod === CONNEXION_METHODE.SSO)
    return { connexionSSO: true }
  else return { connexionSSO: false }
}

export default {
  /**
   * @param state
   * @param commit
   * @param {Object} orga
   * @returns {Promise<void>}
   */
  setLoginOrganization({ state, commit }, orga) {
    if (getRoleByLocalStorage() === ACTUAL_ROLE.TRAINER) {
      commit('SET_LOGIN_ORGANISATION', orga)
      let methods = orga.login_methods

      if (state.login_data.type === TYPE_LOGIN.EMAIL) {
        //warning duplicate in sendLoginMail

        //Used to filter methods with undefined or null value and filter OAuth methods
        methods = methods.filter(methode => {
          return methode.value && methode.type !== MODE_SENDING_CODE.OAUTH
        })

        if (methods.length > 1) {
          commit('SET_LOGIN_METHODES', methods)
          commit('SET_SEEP_LOGIN', LOGIN_STEEP.MULTI_METHODE)
        } else {
          commit('SET_LOGIN_METHODE', methods[0])
          //if sso is the only login method
          if (state.login_data.method.type === MODE_SENDING_CODE.SSO) {
            commit('SET_SSO_TYPE', state.login_data.method.value)
          }
          commit('SET_SEEP_LOGIN', LOGIN_STEEP.END)
        }
      } else if (state.login_data.type === TYPE_LOGIN.PASSWORD) {
        commit('SET_SEEP_LOGIN', LOGIN_STEEP.END)
      }
      return Promise.resolve()
    } else if (getRoleByLocalStorage() === ACTUAL_ROLE.SPONSOR) {
      commit('SET_SPONSOR_LOGIN_ORGANISATION', orga)
      let methods = state.login_data.sponsors.login_methods

      //get methods of selected organization
      let selectedOrganization = orga.organization_uuid
      let finalMethods = []
      methods.forEach(function(el) {
        if (
          el.organizations.find(data => data === selectedOrganization) !==
          undefined
        ) {
          finalMethods.push(el)
        }
      })
      if (
        state.login_data.type === TYPE_LOGIN.EMAIL ||
        state.login_data.type === TYPE_LOGIN.PASSWORD
      ) {
        //warning duplicate in sendLoginMail

        //Used to filter methods with undefined or null value and filter OAuth methods
        finalMethods = finalMethods.filter(methode => {
          return methode.value && methode.type !== MODE_SENDING_CODE.OAUTH
        })
        if (finalMethods.length > 1) {
          commit('SET_LOGIN_METHODES', finalMethods)
          commit('SET_SEEP_LOGIN', LOGIN_STEEP.MULTI_METHODE)
        } else {
          commit('SET_LOGIN_METHODE', finalMethods[0])
          commit('SET_SEEP_LOGIN', LOGIN_STEEP.END)
        }
        return Promise.resolve()
      }
    }
  },

  setSponsorModalLoginOrganization({ state, commit }, orga) {
    commit('SET_SPONSOR_LOGIN_ORGANISATION', orga)
    let methods = state.login_data.sponsors.login_methods

    //get methods of selected organization
    let selectedOrganization = orga.organization_uuid
    let finalMethods = []

    methods.forEach(function(el) {
      if (
        el.organizations.find(data => data === selectedOrganization) !==
        undefined
      ) {
        finalMethods.push(el)
      }
    })
    //warning duplicate in sendLoginMail

    commit('SET_LOGIN_METHODES', finalMethods)

    return Promise.resolve()
  },

  setLoginMethode({ state, commit }, userMethode) {
    commit('SET_LOGIN_METHODE', userMethode)
    if (state.steepLogin !== LOGIN_STEEP.END)
      commit('SET_SEEP_LOGIN', LOGIN_STEEP.END)
    return Promise.resolve()
  },

  CurrentOrganizationId({ state }, OrganizationId) {
    state.CurrentOrganizationId = OrganizationId
  },

  /**
   * @param commit
   * @param state
   * @param {number} currentOrganization - Organization id
   * @returns {Promise<*|string>}
   */
  async logout({ commit, state }, currentOrganization) {
    if (!localStorage.getItem('connexionMethode')) {
      return { simpleLogout: true }
    }
    switch (getRoleByLocalStorage()) {
      case ACTUAL_ROLE.TRAINER:
        let isConnectionSSO = isConnexionMethodSSO(currentOrganization)
        if (isConnectionSSO.connexionSSO) {
          return await logoutTrainer(
            commit,
            state,
            isConnectionSSO,
            currentOrganization
          )
        } else {
          return { simpleLogout: true }
        }
      case ACTUAL_ROLE.SPONSOR:
        return await logoutSponsor(commit, state, state.CurrentOrganizationId)
    }
  },

  resetSteep({ commit }) {
    commit('SET_SEEP_LOGIN', LOGIN_STEEP.NULL)
  },
  finishLogin({ state, dispatch, commit }, browser) {
    //code
    if (state.login_data.type === TYPE_LOGIN.EMAIL) {
      //send Code
      if (state.login_data.method.type === MODE_SENDING_CODE.SSO) {
        //if sso is the only login method
        commit('SET_SSO_TYPE', state.login_data.method.value)
        dispatch('connectWithSso')
          .then(() => {
            return Promise.resolve()
          })
          .catch(e => {
            commit('SET_SEEP_LOGIN', LOGIN_STEEP.ERROR)
            return Promise.reject(e)
          })
      } else {
        switch (getRoleByLocalStorage()) {
          case ACTUAL_ROLE.TRAINER:
            return api.noauth
              .post('login/pin_request', {
                email: state.login_data.email,
                device_uid: state.uuid,
                device_name: `${browser.meta.name}`,
                organization_id: state.login_data.organisationId,
                type: 'connect',
                send_method: state.login_data.method.name
              })
              .then(r => {
                commit('SET_SEEP_LOGIN', LOGIN_STEEP.CODE)
                return Promise.resolve()
              })
              .catch(e => {
                commit('SET_SEEP_LOGIN', LOGIN_STEEP.ERROR)
                return Promise.reject(e)
              })
          case ACTUAL_ROLE.SPONSOR:
            return apiSponsor.noauth
              .post('sponsor/login/pin-request', {
                email: state.login_data.email,
                device_uid: state.uuid,
                device_name: `${browser.meta.name}`,
                send_method: state.login_data.method.name,
                type: 'connect'
              })
              .then(r => {
                commit('SET_SEEP_LOGIN', LOGIN_STEEP.CODE)
                return Promise.resolve()
              })
              .catch(e => {
                commit('SET_SEEP_LOGIN', LOGIN_STEEP.ERROR)
                return Promise.reject(e)
              })
        }
      }
    } else if (state.login_data.type === TYPE_LOGIN.PASSWORD) {
      //sendfind email
      dispatch('validePassword')
        .then(() => {
          return Promise.resolve()
        })
        .catch(e => {
          commit('SET_SEEP_LOGIN', LOGIN_STEEP.ERROR)
          return Promise.reject(e)
        })
    } else {
      commit('SET_SEEP_LOGIN', LOGIN_STEEP.ERROR)
      return Promise.reject()
    }
  },

  sendLoginPassword({ state, commit }, browser) {
    commit('RESET_LOGIN_DATA')
    if (state.login_data.email === '') {
      Bugsnag.notify('try login empty email')
      commit('SET_SEEP_LOGIN', LOGIN_STEEP.ERROR)
      return Promise.reject(LOGIN_STEEP.ERROR)
    }
    commit('SET_SEEP_LOGIN', LOGIN_STEEP.NULL)
    switch (getRoleByLocalStorage()) {
      case ACTUAL_ROLE.TRAINER:
        return api.noauth
          .post('login/info', {
            email: state.login_data.email.trim(),
            device_uid: state.uuid,
            device_name: `${browser.meta.name}`
          })
          .then(({ trainers }) => {
            /** More than one organization */
            if (trainers === null || trainers.length === 0) {
              Bugsnag.notify('login/info trainers = null or empty')
              commit('SET_SEEP_LOGIN', LOGIN_STEEP.ERROR)
              return Promise.reject()
            }

            let organizationList = trainers.filter(
              trainer =>
                trainer.login_methods.find(
                  login_method => login_method.type === 'password'
                ) !== undefined
            )

            if (organizationList.length) {
              if (trainers.length > 1) {
                //multy orga
                commit('SET_SEEP_LOGIN', LOGIN_STEEP.MULTI_ORGANISATION)
                commit('SET_LOGIN_ORGANIZATIONS', trainers)
              } else {
                commit('SET_LOGIN_ORGANIZATION', trainers[0].organization.id)
                commit('SET_SEEP_LOGIN', LOGIN_STEEP.END)
                return Promise.resolve(trainers)
              }
            } else {
              commit('SET_CONNEXION_WITH_PASSWORD_OR_PIN', null)
              commit('SET_ERROR', {
                title: i18n.t('utils.label.error'),
                message: i18n.t('login.error.noOrganizationPassword')
              })
              commit('SET_SEEP_LOGIN', LOGIN_STEEP.ERROR)
            }
          })
          .catch(error => {
            commit('SET_ERROR', {
              title: i18n.t('utils.label.error'),
              message: i18n.t('login.error.noMatchingEmail')
            })
            commit('SET_SEEP_LOGIN', LOGIN_STEEP.ERROR)
            return Promise.reject(error)
          })
      case ACTUAL_ROLE.SPONSOR:
        return apiSponsor.noauth
          .post('sponsor/login/info', {
            email: state.login_data.email.trim(),
            app_version: process.env.VUE_APP_VERSION,
            os: 'Web'
          })
          .then(sponsors => {
            /** More than one organization */
            if (
              sponsors.organizations === null ||
              sponsors.organizations.length === 0
            ) {
              Bugsnag.notify(
                'sponsor/login/info sponsor.organizations = null or empty'
              )
              commit('SET_SEEP_LOGIN', LOGIN_STEEP.ERROR)
              return Promise.reject()
            }

            if (sponsors.organizations.length > 1) {
              //multy orga
              commit('SET_SEEP_LOGIN', LOGIN_STEEP.MULTI_ORGANISATION)
              commit('SET_LOGIN_ORGANIZATIONS', sponsors)
            } else {
              commit(
                'SET_LOGIN_ORGANIZATION',
                sponsors.organizations[0].organization_uuid
              )
              commit('SET_ERROR', {
                title: i18n.t('utils.label.error'),
                message: i18n.t('login.error.noOrganizationPassword')
              })
              commit('SET_SEEP_LOGIN', LOGIN_STEEP.END)
              return Promise.resolve(sponsors)
            }
          })
          .catch(error => {
            commit('SET_ERROR', {
              title: i18n.t('utils.label.error'),
              message: i18n.t('login.error.noMatchingEmail')
            })
            commit('SET_SEEP_LOGIN', LOGIN_STEEP.ERROR)
            return Promise.reject(error)
          })
    }
  },
  sendLoginEntreprise({ state, commit }, browser) {
    commit('RESET_LOGIN_DATA')
    commit('SET_SEEP_LOGIN', LOGIN_STEEP.ENTREPRISE)
  },
  loginInfo({ state, commit }, { browser, email }) {
    return api.noauth
      .post('login/info', {
        email: email.trim(),
        device_uid: localStorage.getItem('uuid'),
        device_name: `${browser.meta.name}`
      })
      .then(({ trainers }) => {
        commit('SET_LOGIN_ORGANIZATIONS', trainers)

        return Promise.resolve(trainers)
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },

  isSsoGoggleOrMicrosoftAuthorize({ state, commit }, { browser, email }) {
    return api.noauth
      .post('login/info', {
        email: email.trim(),
        device_uid: localStorage.getItem('uuid'),
        device_name: `${browser.meta.name}`
      })
      .then(({ trainers }) => {
        return Promise.resolve(trainers)
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },

  sponsorLoginInfo({ state, commit }, email) {
    return apiSponsor.noauth
      .post('sponsor/login/info', {
        email: email ? email : state.login_data.email.trim(),
        app_version: process.env.VUE_APP_VERSION,
        os: 'Web'
      })
      .then(sponsor => {
        commit('SET_LOGIN_ORGANIZATIONS', sponsor)
        if (email) {
          commit('SET_USER_EMAIL', email)
        }
        return Promise.resolve(sponsor)
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },

  trainerSendLoginMailLoginFormModal({ state, commit }, organization) {
    commit('SET_LOGIN_ORGANIZATION', organization.organizationId)

    //warning duplicate in setLoginOrganization
    let methods = organization.organizationLoginMethod

    //Used to filter methods with undefined or null value and filter OAuth methods
    methods = methods.filter(methode => {
      return methode.value && methode.type !== MODE_SENDING_CODE.OAUTH
    })
    if (methods.length > 1) {
      commit('SET_LOGIN_METHODES', methods)
      commit('SET_SEEP_LOGIN', LOGIN_STEEP.MULTI_METHODE)
    } else {
      commit('SET_LOGIN_METHODE', methods[0])
      commit('SET_SEEP_LOGIN', LOGIN_STEEP.END)
    }
  },

  sendLoginMail({ state, commit }, browser) {
    commit('RESET_LOGIN_DATA')
    if (state.login_data.email === '') {
      Bugsnag.notify('try login empty email')
      commit('SET_SEEP_LOGIN', LOGIN_STEEP.ERROR)
      return Promise.reject()
    }
    commit('SET_SEEP_LOGIN', LOGIN_STEEP.NULL)
    switch (getRoleByLocalStorage()) {
      case ACTUAL_ROLE.TRAINER:
        return api.noauth
          .post('login/info', {
            email: state.login_data.email.trim(),
            device_uid: state.uuid,
            device_name: `${browser.meta.name}`
          })
          .then(({ trainers }) => {
            /** More than one organization */
            if (trainers === null || trainers.length === 0) {
              Bugsnag.notify('login/info trainers = null or empty')
              commit('SET_SEEP_LOGIN', LOGIN_STEEP.ERROR)
              return Promise.reject()
            }
            if (trainers.length > 1) {
              //multy orga
              commit('SET_SEEP_LOGIN', LOGIN_STEEP.MULTI_ORGANISATION)
              commit('SET_LOGIN_ORGANIZATIONS', trainers)
            } else {
              commit('SET_LOGIN_ORGANIZATION', trainers[0].organization.id)

              //warning duplicate in setLoginOrganization
              let methodes = trainers[0].login_methods

              //Used to filter methods with undefined or null value and filter OAuth methods
              methodes = methodes.filter(methode => {
                return methode.value && methode.type !== MODE_SENDING_CODE.OAUTH
              })
              if (methodes.length > 1) {
                commit('SET_LOGIN_METHODES', methodes)
                commit('SET_SEEP_LOGIN', LOGIN_STEEP.MULTI_METHODE)
              } else {
                commit('SET_LOGIN_METHODE', methodes[0])
                commit('SET_SEEP_LOGIN', LOGIN_STEEP.END)
              }
              return Promise.resolve()
            }
          })
          .catch(error => {
            commit('SET_ERROR', {
              title: i18n.t('utils.label.error'),
              message: i18n.t('login.error.noMatchingEmail')
            })
            commit('SET_SEEP_LOGIN', LOGIN_STEEP.ERROR)
            return Promise.reject(error)
          })
      case ACTUAL_ROLE.SPONSOR:
        return apiSponsor.noauth
          .post('sponsor/login/info', {
            email: state.login_data.email.trim(),
            app_version: process.env.VUE_APP_VERSION,
            os: 'Web'
          })
          .then(sponsors => {
            /** More than one organization */
            if (
              sponsors.organizations === null ||
              sponsors.organizations.length === 0
            ) {
              Bugsnag.notify('login/info trainers = null or empty')
              commit('SET_SEEP_LOGIN', LOGIN_STEEP.ERROR)
              return Promise.reject()
            }

            commit('SET_SEEP_LOGIN', LOGIN_STEEP.MULTI_ORGANISATION)
            commit('SET_LOGIN_ORGANIZATIONS', sponsors)
            return Promise.resolve(sponsors)
          })
          .catch(error => {
            commit('SET_ERROR', {
              title: i18n.t('utils.label.error'),
              message: i18n.t('login.error.noMatchingEmail')
            })
            commit('SET_SEEP_LOGIN', LOGIN_STEEP.ERROR)
            return Promise.reject(error)
          })
    }
  },
  connectWithGoogle(
    { state, commit, dispatch },
    { email, id_token, organisationId }
  ) {
    return api.noauth
      .post('login/oauth/google', {
        email: email,
        id_token: id_token,
        device_uid: localStorage.getItem('uuid'),
        organization_id:
          state.login_data.organisationId || parseInt(organisationId)
      })
      .then(({ token, trainer }) => {
        localStorage.setItem('sso_value', SSO_VALUE.GOOGLE)
        commit(
          'SET_LOGIN_ORGANIZATION',
          state.login_data.organisationId || parseInt(organisationId)
        )
        commit('USER_LOGED', {
          token,
          trainer,
          uuid: localStorage.getItem('uuid'),
          organization_id:
            state.login_data.organisationId || parseInt(organisationId),
          stayConnected: true,
          connexionMethode: CONNEXION_METHODE.SSO_GOOGLE
        })
        return Promise.resolve()
      })
      .catch(e => {
        commit('SET_FATAL_ERROR', e)
        return Promise.reject(e)
      })
  },
  connectWithMicrosoft(
    { state, commit, dispatch },
    { login, token, organisationId }
  ) {
    return api.noauth
      .post('login/oauth/azure', {
        email: login,
        access_token: token,
        device_uid: localStorage.getItem('uuid'),
        organization_id:
          state.login_data.organisationId || parseInt(organisationId)
      })
      .then(({ token, trainer }) => {
        localStorage.setItem('sso_value', SSO_VALUE.MICROSOFT)
        commit(
          'SET_LOGIN_ORGANIZATION',
          state.login_data.organisationId || parseInt(organisationId)
        )
        commit('USER_LOGED', {
          token,
          trainer,
          uuid: localStorage.getItem('uuid'),
          organization_id:
            state.login_data.organisationId || parseInt(organisationId),
          stayConnected: true,
          connexionMethode: CONNEXION_METHODE.SSO_MICROSOFT
        })
        return Promise.resolve()
      })
      .catch(e => {
        commit('SET_FATAL_ERROR', e)
        return Promise.reject(e)
      })
  },

  setCurrentMicrosoftAccount({ commit }, data) {
    commit('SET_MICROSOFT_ACCOUNT', data)
  },

  /** Connection request for SSO ADFS */
  connectWithSso({ state, commit }) {
    // Need to store uuid or it will change before validateSso
    localStorage.setItem('uuid', state.uuid)
    return api.noauth
      .post('login/sso/adfs', {
        email: state.login_data.email,
        device_uid: localStorage.getItem('uuid'),
        organization_id: state.login_data.organisationId,
        app_redirect_url: window.location.origin + '/login', //todo
        sso_value: state.login_data.ssoValue
      })
      .then(response => {
        localStorage.setItem('secret_token', response.secret_token)
        localStorage.setItem('url_redirection', response.url_redirection)
        localStorage.setItem('organization_id', state.login_data.organisationId)
        sessionStorage.setItem(
          'organization_id',
          state.login_data.organisationId
        )
        localStorage.setItem('sso_value', state.login_data.ssoValue)

        window.location.href = response.url_redirection
        localStorage.removeItem('url_redirection')
        commit('SET_SEEP_LOGIN', LOGIN_STEEP.REDIRECT)
        return Promise.resolve()
      })
      .catch(error => {
        commit('SET_ERROR', {
          title: i18n.t('utils.label.error'),
          message: error.response.data.detail
        })
        commit('SET_SEEP_LOGIN', LOGIN_STEEP.ERROR)
        localStorage.removeItem('url_redirection')
        return Promise.reject(error)
      })
  },
  /** Validation of SSO connection */
  validateSso({ state, commit, dispatch }) {
    return api.noauth
      .post('login/sso/adfs/validate', {
        secret_token: localStorage.getItem('secret_token')
      })
      .then(({ token, trainer }) => {
        /** Log the user and send him in the home page */
        commit(
          'SET_LOGIN_ORGANIZATION',
          localStorage.getItem('organization_id')
        )
        commit('USER_LOGED', {
          token,
          trainer,
          uuid: localStorage.getItem('uuid'),
          organization_id: localStorage.getItem('organization_id'),
          stayConnected: true,
          connexionMethode: CONNEXION_METHODE.SSO
        })
        commit('SET_SEEP_LOGIN', LOGIN_STEEP.END_CODE)
        return Promise.resolve()
      })
      .catch(error => {
        commit('SET_SEEP_LOGIN', LOGIN_STEEP.ERROR)
        return Promise.reject(error)
      })
  },

  valideCodeEntreprise({ state, commit }, code) {
    return api.noauth
      .post('login/enterprise_access', {
        code: code,
        device_uid: state.uuid
      })
      .then(({ token, trainer }) => {
        commit('SET_LOGIN_ORGANIZATION', trainer.organization.id)
        commit('USER_LOGED', {
          token,
          trainer,
          uuid: state.uuid,
          organization_id: trainer.organization.id,
          stayConnected: true,
          connexionMethode: CONNEXION_METHODE.MODE_COMPANY
        })
        commit('SET_SEEP_LOGIN', LOGIN_STEEP.END_CODE)
        return Promise.resolve()
      })
      .catch(error => {
        if (error.response && error.response.status === 403) {
          let detail = error.response.data.detail
          commit('SET_ERROR', {
            title: i18n.t('utils.label.error'),
            message: `${ERROR_403[detail].message}`
          })
        } else {
          commit('SET_ERROR', {
            title: i18n.t('utils.label.error'),
            message: i18n.t('login.error.error')
          })
        }
        commit('SET_SEEP_LOGIN', LOGIN_STEEP.ERROR)
        return Promise.reject(error)
      })
  },

  SponsorValidCodeEnterprise({ state, commit }, code) {
    return apiSponsor.noauth
      .post('sponsor/login/enterprise-access', {
        code: code,
        device_uid: state.uuid
      })
      .then(({ token, sponsor_user, sponsors }) => {
        commit('SET_LOGIN_ORGANIZATION', sponsors.organization_uuid)
        commit('SPONSOR_USER_LOGGED', {
          token,
          sponsor_user,
          sponsors,
          uuid: state.uuid,
          organization_id: state.login_data.organisationId,
          stayConnected: true,
          connexionMethode: CONNEXION_METHODE.MODE_COMPANY
        })
        commit('SET_SEEP_LOGIN', LOGIN_STEEP.END_CODE)
        return Promise.resolve()
      })
      .catch(error => {
        if (error.response && error.response.status === 403) {
          let detail = error.response.data.detail
          commit('SET_ERROR', {
            title: i18n.t('utils.label.error'),
            message: `${ERROR_403[detail].message}`
          })
        } else {
          commit('SET_ERROR', {
            title: i18n.t('utils.label.error'),
            message: i18n.t('login.error.error')
          })
        }
        commit('SET_SEEP_LOGIN', LOGIN_STEEP.ERROR)
        return Promise.reject(error)
      })
  },

  /** Email method
   *
   * Verify that a code is valid for a user
   */
  valideCode({ commit, state, dispatch }, code) {
    switch (getRoleByLocalStorage()) {
      case ACTUAL_ROLE.TRAINER:
        return api.noauth
          .post('login/pin_verify', {
            email: state.login_data.email,
            device_uid: state.uuid,
            organization_id: state.login_data.organisationId,
            code: code
          })
          .then(({ token, trainer }) => {
            /** Log the user and send him in the home page */
            commit('USER_LOGED', {
              token,
              trainer,
              uuid: state.uuid,
              organization_id: state.login_data.organisationId,
              stayConnected: true,
              connexionMethode: CONNEXION_METHODE.EMAIL
            })
            commit('SET_SEEP_LOGIN', LOGIN_STEEP.END_CODE)
            return Promise.resolve()
          })
          .catch(error => {
            if (error.response && error.response.status === 403) {
              let detail = error.response.data.detail
              commit('SET_ERROR', {
                title: i18n.t('utils.label.error'),
                message: `${ERROR_403[detail].message}`
              })
            } else {
              commit('SET_ERROR', {
                title: i18n.t('login.label.wrongCode'),
                message: ERROR_403.ERROR.message
              })
            }
            return Promise.reject(error)
          })
      case ACTUAL_ROLE.SPONSOR:
        return apiSponsor.noauth
          .post('sponsor/login/pin-verify', {
            email: state.login_data.email,
            device_uid: state.uuid,
            organization_uuid: state.login_data.organisationId,
            code: code
          })
          .then(({ token, sponsor_user, sponsors }) => {
            /** Log the user and send him in the account page */
            commit('SPONSOR_USER_LOGGED', {
              token,
              sponsor_user,
              sponsors,
              uuid: state.uuid,
              organization_id: state.login_data.organisationId,
              stayConnected: true,
              connexionMethode: CONNEXION_METHODE.EMAIL
            })
            commit('SET_SEEP_LOGIN', LOGIN_STEEP.END_CODE)
            return Promise.resolve()
          })
          .catch(error => {
            if (error.response && error.response.status === 403) {
              let detail = error.response.data.detail
              commit('SET_ERROR', {
                title: i18n.t('utils.label.error'),
                message: `${ERROR_403[detail].message}`
              })
            } else {
              commit('SET_ERROR', {
                title: i18n.t('login.label.wrongCode'),
                message: ERROR_403.ERROR.message
              })
            }
            return Promise.reject(error)
          })
    }
  },
  /** Password Method
   *
   * Verify the password
   */
  validePassword({ state, commit, dispatch }) {
    switch (getRoleByLocalStorage()) {
      case ACTUAL_ROLE.TRAINER:
        return api.noauth
          .post('login/password_login', {
            email: state.login_data.email,
            device_uid: state.uuid,
            organization_id: state.login_data.organisationId,
            password: state.login_data.password
          })
          .then(({ token, trainer }) => {
            commit('SET_USER_PASSWORD', '')
            /** Log the user and send him in the home page */
            commit('USER_LOGED', {
              token,
              trainer,
              uuid: state.uuid,
              organization_id: state.login_data.organisationId,
              connexionMethode: CONNEXION_METHODE.PASSWORD
            })
            commit('SET_SEEP_LOGIN', LOGIN_STEEP.END_CODE)
            return Promise.resolve()
          })
          .catch(error => {
            if (error.response && error.response.status === 403) {
              let detail = error.response.data.detail
              commit('SET_ERROR', {
                title: i18n.t('utils.label.error'),
                message: `${ERROR_403[detail].message}`
              })
            } else {
              commit('SET_ERROR', {
                title: i18n.t('login.label.incorrectCredential'),
                message: i18n.t('login.error.invalidCredentials')
              })
            }
            commit('SET_SEEP_LOGIN', LOGIN_STEEP.ERROR)
            return Promise.reject(error)
          })
      case ACTUAL_ROLE.SPONSOR:
        return apiSponsor.noauth
          .post('sponsor/login/password-login', {
            email: state.login_data.email,
            device_uid: state.uuid,
            password: state.login_data.password,
            organization_uuid: state.login_data.organisationId
          })
          .then(({ token, sponsor_user, sponsors }) => {
            commit('SET_USER_PASSWORD', '')
            /** Log the user and send him in the home page */
            commit('SPONSOR_USER_LOGGED', {
              token,
              sponsor_user,
              sponsors,
              uuid: state.uuid,
              organization_id: state.login_data.organisationId,
              connexionMethode: CONNEXION_METHODE.PASSWORD
            })
            commit('SET_SEEP_LOGIN', LOGIN_STEEP.END_CODE)
            return Promise.resolve()
          })
          .catch(error => {
            if (error.response && error.response.status === 403) {
              let detail = error.response.data.detail
              commit('SET_ERROR', {
                title: i18n.t('utils.label.error'),
                message: `${ERROR_403[detail].message}`
              })
            } else {
              commit('SET_ERROR', {
                title: i18n.t('login.label.incorrectCredential'),
                message: i18n.t('login.error.invalidCredentials')
              })
            }
            commit('SET_SEEP_LOGIN', LOGIN_STEEP.ERROR)
            return Promise.reject(error)
          })
    }
  },
  /** Email Method
   *
   * Permit to resend a code to the email address of the user
   */
  resendCode({ state, commit }) {
    return api.noauth
      .post('login/pin_resend', {
        email: state.login_data.email,
        device_uid: state.uuid,
        organization_id: state.login_data.organisationId,
        send_method: state.login_data.method.name
      })
      .then(result => {
        return Promise.resolve(result)
      })
      .catch(error => {
        if (error.response && error.response.status === 403) {
          let detail = error.response.data.detail
          commit('SET_ERROR', {
            title: i18n.t('utils.label.error'),
            message: `${ERROR_403[detail].message}`
          })
        } else {
          commit('SET_ERROR', {
            title: i18n.t('login.label.cantResendCode'),
            message: i18n.t('login.error.error')
          })
          commit('SHOW_CODE_MODAL', false)
        }
        commit('SET_SEEP_LOGIN', LOGIN_STEEP.ERROR)
        return Promise.reject(error)
      })
  },
  /** Email Method
   *
   * Permit to resend a code to the email address of the sponsor user
   */
  resendCodeSponsor({ state, commit }) {
    return apiSponsor.noauth
      .post('sponsor/login/pin-resend', {
        email: state.login_data.email,
        device_uid: state.uuid,
        send_method: 'email',
        type: 'connect'
      })
      .then(result => {
        return Promise.resolve(result)
      })
      .catch(error => {
        if (error.response && error.response.status === 403) {
          let detail = error.response.data.detail
          commit('SET_ERROR', {
            title: i18n.t('utils.label.error'),
            message: `${ERROR_403[detail].message}`
          })
        } else {
          commit('SET_ERROR', {
            title: i18n.t('login.label.cantResendCode'),
            message: i18n.t('login.error.error')
          })
          commit('SHOW_CODE_MODAL', false)
        }
        commit('SET_SEEP_LOGIN', LOGIN_STEEP.ERROR)
        return Promise.reject(error)
      })
  },
  /**
   * Need to refresh a user
   */
  tokenCheck() {
    return api.noauth
      .post('login/token_check', {
        email: localStorage.getItem('email') || sessionStorage.getItem('email'),
        device_uid:
          localStorage.getItem('uuid') || sessionStorage.getItem('uuid'),
        organization_id:
          localStorage.getItem('organization_id') ||
          sessionStorage.getItem('organization_id')
      })
      .then(({ RESPONSE }) => {
        return Promise.resolve(RESPONSE)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  /** Get back the user store in the sessionStorage */
  getUser({ commit }) {
    if (getRoleByLocalStorage() === ACTUAL_ROLE.TRAINER) {
      return api.auth
        .get(`trainers/${sessionStorage.getItem('trainerIdSession')}`)
        .then(trainer => {
          commit('USER_REFRESH', {
            trainer
          })

          return api.auth
            .get(`organizations/${trainer.organization.id}/settings`)
            .then(organization => {
              organization = {
                ...organization,
                ...{ id: trainer.organization.id }
              }

              commit('SET_ORGANIZATION', {
                organization
              })

              return api.noauth
                .get(`logo-web-app`, { favicon: 1 })
                .then(logos => {
                  commit('SET_LOGOS', {
                    logos
                  })
                  return Promise.resolve()
                })
                .catch(e => {
                  return Promise.reject(e)
                })

              return Promise.resolve()
            })
            .catch(e => {
              return Promise.reject(e)
            })
        })
        .catch(e => {
          return Promise.reject(e)
        })
    } else if (getRoleByLocalStorage() === ACTUAL_ROLE.SPONSOR) {
      return apiSponsor.authSponsor
        .get(`sponsor/account/me`)
        .then(sponsor => {
          commit('SPONSOR_USER_REFRESH', sponsor)
          return Promise.resolve()
        })
        .catch(e => {
          console.log(e)
        })
    }
  },

  getOrganizationBySwitch({ commit, state }, organizationId) {
    return api.auth
      .get(`organizations/${organizationId}/settings`)
      .then(organization => {
        organization = {
          ...organization,
          ...{ id: organizationId }
        }
        commit('SET_ORGANIZATION', {
          organization
        })
        return Promise.resolve()
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },

  /** Get the user organization */
  getOrganizationByModalConnection({ commit, state }) {
    let selectedOrganization = localStorage.getItem('selectedOrganization')
    return api.auth
      .get(`organizations/${selectedOrganization}/settings`)
      .then(organization => {
        organization = {
          ...organization,
          ...{ id: selectedOrganization }
        }

        let trainerUser = JSON.parse(localStorage.getItem('trainerUser'))
        trainerUser[state.user.email].forEach(el => {
          if (el.organizationId === Number(organization.id)) {
            el.organizationToken = localStorage.getItem('token')
            el.currentConnectionMethod = localStorage.getItem(
              'connexionMethode'
            )
            el.organizationLogoUrl = organization.logo_url
            el.trainerId = state.user.id
            el.ssoValue = localStorage.getItem('sso_value')
              ? localStorage.getItem('sso_value')
              : null
          }
        })
        localStorage.setItem('trainerUser', JSON.stringify(trainerUser))
        commit('SET_ORGANIZATION', {
          organization
        })
        commit('SET_SEEP_LOGIN', LOGIN_STEEP.REDIRECT)
        return Promise.resolve(organization)
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },

  /** Get the user organization */
  getOrganization({ commit, state }) {
    return api.auth
      .get(`organizations/${state.login_data.organisationId}/settings`)
      .then(organization => {
        organization = {
          ...organization,
          ...{ id: state.login_data.organisationId }
        }
        commit('SET_ORGANIZATION', {
          organization
        })
        commit('SET_SEEP_LOGIN', LOGIN_STEEP.REDIRECT)
        return Promise.resolve(organization)
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },

  redirectSponsorDocumentsPage({ commit, state }) {
    commit('SET_SEEP_LOGIN', LOGIN_STEEP.REDIRECT)
  },

  getLogoUrl({ commit, state }) {
    return api.noauth
      .get(`logo-web-app`)
      .then(res => {
        return Promise.resolve(res)
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },
  getOrganizationParam({ commit }, { organisationId }) {
    return api.auth
      .get(`organizations/${organisationId}/settings`)
      .then(organization => {
        organization = {
          ...organization,
          ...{ id: organisationId }
        }

        commit('SET_ORGANIZATION', {
          organization
        })
        return Promise.resolve(organization)
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },
  setHandshake({ commit, state }, browser) {
    return api.auth
      .post('handshake', {
        trainer_id: state.user.id,
        device_uid: state.uuid,
        type: 'web',
        os: 'web',
        browser: browser.meta.name,
        app_version: process.env.version
      })
      .then(() => {
        localStorage.setItem(
          `${localStorage.getItem('email')} / 'handshake'`,
          dayjs().toISOString()
        )
        commit('SET_HANDSHAKE')
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },

  setSponsorHandshake({ commit, state }, browser) {
    return apiSponsor.authSponsor
      .post('sponsor/handshake', {
        device_uid: state.uuid,
        device_name: `${browser.meta.name}`,
        type: 'web',
        os: 'web',
        browser: browser.meta.name,
        app_version: process.env.VUE_APP_VERSION
      })
      .then(() => {
        localStorage.setItem(
          `${localStorage.getItem('email')} / 'handshake'`,
          dayjs().toISOString()
        )
        commit('SET_SPONSOR_HANDSHAKE')
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },

  changeUserEmail({ commit }, userEmail) {
    commit('SET_USER_EMAIL', userEmail)
  },
  changeUserPassword({ commit }, userPassword) {
    commit('SET_USER_PASSWORD', userPassword)
  },

  changeConnexionMethod({ commit }, mewType) {
    commit('SET_CONNEXION_TYPE', mewType)
  },
  setUuid({ commit }, setUuid) {
    localStorage.setItem('uuid', setUuid)
    sessionStorage.setItem('uuid', setUuid)
    sessionStorage.setItem('uuidSession', setUuid)
  },
  getOrGenerate({ commit, state }) {
    const uuid =
      state.uuid ||
      localStorage.getItem('uuid') ||
      sessionStorage.getItem('uuid') ||
      new Date().getTime().toString(36) +
        Math.random()
          .toString(36)
          .substr(2, 9)
    localStorage.setItem('uuid', uuid)
    sessionStorage.setItem('uuid', uuid)
    sessionStorage.setItem('uuidSession', uuid)

    commit('SET_UUID', uuid)
    return Promise.resolve(uuid)
  },
  setError({ commit }, error) {
    commit('SET_ERROR', error)
  },
  setToken({ commit }, token) {
    commit('SET_TOKEN', { token })
  },
  setUserId({ commit }, trainerId) {
    commit('SET_USER_ID', { trainerId })
  },
  setConnexionMethode({ commit }, methode) {
    commit('SET_CONNEXION_METHODE', methode)
  },
  setTypeLogin({ commit }, methode) {
    commit('SET_TYPE_LOGIN', methode)
  },
  setConnectionWithPasswordOrPin({ commit }, data) {
    commit('SET_CONNEXION_WITH_PASSWORD_OR_PIN', data)
  },
  setMicrosoftEmailInvalid({ commit }, bool) {
    commit('SET_MICROSOFT_EMAIL_INVALID', bool)
  },
  setTypeMode({ commit }, typeMode) {
    commit('SET_TYPE_MODE', typeMode)
  },
  setSponsorHasPassword({ commit }, bool) {
    commit('SET_SPONSOR_USER_HAS_PASSWORD', bool, { root: bool })
  },
  setTrainerHasPassword({ commit }, bool) {
    commit('SET_USER_HAS_PASSWORD', bool, { root: bool })
  },
  setSponsorOrganizationsName({ commit }, organizationsList) {
    commit('SET_SPONSOR_ORGANIZATIONS_NAME', organizationsList)
  },
  sponsorPinRequest({ state }, browser) {
    return apiSponsor.noauth
      .post('sponsor/login/pin-request', {
        email: state.login_data.email,
        device_uid: state.uuid,
        device_name: `${browser.meta.name}`,
        send_method: 'email',
        type: 'connect'
      })
      .then(r => {
        return Promise.resolve()
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },
  sponsorValidCode({ commit, state }, { code, currentOrganizationUuid }) {
    return apiSponsor.noauth
      .post('sponsor/login/pin-verify', {
        email: state.login_data.email,
        device_uid: state.uuid,
        organization_uuid: currentOrganizationUuid,
        code: code
      })
      .then(({ token, sponsor_user, sponsors }) => {
        return Promise.resolve()
      })
      .catch(error => {
        if (error.response && error.response.status === 403) {
          let detail = error.response.data.detail
          commit('SET_ERROR', {
            title: i18n.t('utils.label.error'),
            message: `${ERROR_403[detail].message}`
          })
        } else {
          commit('SET_ERROR', {
            title: i18n.t('login.label.wrongCode'),
            message: ERROR_403.ERROR.message
          })
        }
        return Promise.reject(error)
      })
  },
  sponsorValidPassword({ state, commit }, currentOrganizationUuid) {
    return apiSponsor.noauth
      .post('sponsor/login/password-login', {
        email: state.login_data.email,
        device_uid: state.uuid,
        password: state.login_data.password,
        organization_uuid: currentOrganizationUuid
      })
      .then(({ token, sponsor_user, sponsors }) => {
        return Promise.resolve()
      })
      .catch(error => {
        if (error.response && error.response.status === 403) {
          let detail = error.response.data.detail
          commit('SET_ERROR', {
            title: i18n.t('utils.label.error'),
            message: `${ERROR_403[detail].message}`
          })
        } else {
          commit('SET_ERROR', {
            title: i18n.t('login.label.incorrectCredential'),
            message: i18n.t('login.error.invalidCredentials')
          })
        }
        return Promise.reject(error)
      })
  },
  setTrainerUser({ state, commit }, trainerUser) {
    commit('SET_TRAINER_USER', trainerUser)
  },
  setIsLastConnectedOrganization({ state, commit }, bool) {
    commit('SET_IS_LAST_CONNECTED_ORGANIZATION', bool)
  },
  setUserLogout({ state, commit }) {
    commit('USER_LOGOUT')
  },
  setStepLogin({ state, commit }, step) {
    commit('SET_SEEP_LOGIN', step)
  }
}
